<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="Kiosk-sites-page">
    <b-card class="" title="Unbilled Enterprises">
      <b-card-text>View all enterprises that have not been billed</b-card-text>
    </b-card>

    <b-card no-body class="mb-0">
      <section>
        <b-table
          class="data-table"
          hover
          :items="enterprises"
          :fields="headers"
        >
          <template #cell(enterprise_uid)="data">
            <router-link :to="{ name: 'enterprise-view', params: { uid: data.item.enterprise_uid }}">
              {{ data.item.enterprise_uid }}
            </router-link>
          </template>

          <template #cell(total_amount)="data">
            {{ data.item.total_amount | intToCurrency }}
          </template>

          <template #cell(actions)="data">
            <b-btn
              @click="createBill(data.item.enterprise_uid)"
              variant="primary"
              class="ml-2">Create Bill
            </b-btn>
          </template>
        </b-table>
        <b-row>
          <b-col class="ml-2 mt-1" v-if="enterprises.length === 0">
            <p >No results</p>
          </b-col>
        </b-row>
      </section>
    </b-card>
  </div>
</template>
<!--eslint-enable-->

<script>
import helperService from '@/services/HelperService';
import BillService from '@/services/BillService';

export default {
  name: 'BillsView',
  components: {},
  props: {},
  data() {
    return {
      selectedEnterprise: null,
      enterprises: [],
      headers: [
        {
          key: 'enterprise_uid',
          label: 'Enterprise UID',
        },
        {
          key: 'enterprise_display_name',
          label: 'Name',
        },
        {
          key: 'total_amount',
          label: 'Total Amount',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
    };
  },
  mounted() {
    this.getUnbilledEnterprises();
  },
  methods: {
    async getUnbilledEnterprises() {
      try {
        const res = await BillService.getUnbilledEnterprises()
        this.enterprises = res.data
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not get unbilled enterprises, please refresh and try again')
      }
    },
    async createBill(enterpriseUid) {
      try {
        const request = {
          enterprise_uid: enterpriseUid,
        }
        const bill = await BillService.createBill(request)
        helperService.showNotfySuccess(this.$toast, 'Bill Created Successfully')
        await this.$router.push({ name: 'bill-details', params: { id: bill.data.id } })
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not get create Bill, please refresh and try again')
      }
    },
    closeModals() {
      this.$bvModal.hide('modal-delete-kiosk-site');
      this.$bvModal.hide('modal-create-kiosk-site')
    },
    showDeleteModal(site, modalName) {
      this.selectedSite = site;
      this.$bvModal.show(modalName);
    },
  },
};
</script>
